<template>
  <header class="personal__header">
    <nav class="tabs overflow-x">
      <ul class="tabs__list">
        <template v-for="(item, index) in tabs">
          <router-link
            class="personal__tab tabs__item"
            :key="index"
            :to="item.to"
            active-class="tabs__item--active"
            tag="li"
          >
            {{ item.name }}
          </router-link>
        </template>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    list: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    userGroup() {
      return this.$store.getters.getUserGroup;
    },
    tabs() {
      return this.list.filter((item) => item.group.indexOf(this.userGroup) !== -1);
    },
  },
};
</script>
