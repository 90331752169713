<template>
  <article class="personal">
    <div class="container">
      <div class="personal__inner">
        <Tabs
          :list="tabs"
        />

        <router-view></router-view>
      </div>
    </div>
  </article>
</template>

<script>
import Tabs from '@/components/Tabs.vue';

export default {
  name: 'Personal',
  components: {
    Tabs,
  },
  data() {
    return {
      activeTab: 'sendReceipt',
      tabs: [
        {
          name: 'Отправить чек',
          to: '/personal/send-receipt/',
          group: ['admin', 'partner'],
        },
        {
          name: 'Клиенты',
          to: '/personal/clients/',
          group: ['admin', 'partner'],
        },
        {
          name: 'Чеки',
          to: '/personal/receipts/',
          group: ['admin'],
        },
        {
          name: 'Партнеры',
          to: '/personal/partners/',
          group: ['admin'],
        },
        {
          name: 'Параметры',
          to: '/personal/settings/',
          group: ['admin'],
        },
        {
          name: 'Методика',
          to: '/personal/methodology/',
          group: ['methodology'],
        },
      ],
    };
  },
};
</script>
